import { CloseCircleOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Popconfirm, theme } from "antd";
import PropTypes from "prop-types";

import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from "./define";
import { PrimaryButtonStyle } from "./styled";

export function PrimaryButton({
  icon,
  size = "middle",
  onClick = () => {},
  type = BUTTON_TYPES.PRIMARY,
  children,
  ...props
}) {
  const {
    token: {
      Button: { colorBgPrimary, colorBgBlue, colorBgPink, colorBgYellow, colorBgSecondary, colorBgPurple },
      Palette: { primary, white, purple, yellow, pink, blue }
    }
  } = theme.useToken();

  const bgColor = (() => {
    switch (type) {
      case BUTTON_TYPES.LIGHT_PRIMARY:
        return;
      case BUTTON_TYPES.DARK_BLUE:
        return blue.dark;
      case BUTTON_TYPES.SECONDARY:
        return colorBgSecondary;
      case BUTTON_TYPES.LIGHT_BLUE:
        return blue.extraLight;
      case BUTTON_TYPES.BLUE:
        return colorBgBlue;
      case BUTTON_TYPES.PINK:
        return colorBgPink;
      case BUTTON_TYPES.YELLOW:
        return colorBgYellow;
      case BUTTON_TYPES.DARK_YELLOW:
        return yellow.normal;
      case BUTTON_TYPES.DARK_PINK:
        return pink.normal;
      case BUTTON_TYPES.PURPLE:
        return colorBgPurple;
      case BUTTON_TYPES.PRIMARY:
      default:
        return colorBgPrimary;
    }
  })();

  const fontColor = (() => {
    switch (type) {
      case BUTTON_TYPES.YELLOW:
        return yellow.dark;
      case BUTTON_TYPES.PINK:
        return pink.dark;
      case BUTTON_TYPES.PURPLE:
        return purple.dark;
      case BUTTON_TYPES.LIGHT_BLUE:
        return blue.normal;
      case BUTTON_TYPES.LIGHT_PRIMARY:
        return primary;
      case BUTTON_TYPES.PRIMARY:
      case BUTTON_TYPES.SECONDARY:
      case BUTTON_TYPES.BLUE:
      case BUTTON_TYPES.DARK_YELLOW:
      case BUTTON_TYPES.DARK_PINK:
      case BUTTON_TYPES.DARK_BLUE:
      default:
        return white;
    }
  })();

  return (
    <PrimaryButtonStyle
      onClick={onClick}
      fontcolor={fontColor}
      background={bgColor}
      borderColor={type.startsWith("light") ? fontColor : bgColor}
      icon={icon}
      size={size}
      {...props}
    >
      {children}
    </PrimaryButtonStyle>
  );
}

PrimaryButton.propTypes = {
  background: PropTypes.string,
  icon: PropTypes.element,
  size: PropTypes.oneOf(Object.values(BUTTON_SIZE_TYPES)),
  fontColor: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES))
};

export function FilterActionButtons(props) {
  const onFilter = (e) => {
    e.preventDefault();
    props.onFilter();
  };
  const onClear = (e) => {
    e.preventDefault();
    props.onClear();
  };
  return (
    <div>
      <PrimaryButton onClick={onFilter}>
        <FilterOutlined />
        篩選
      </PrimaryButton>
      <PrimaryButton ml={1} type={BUTTON_TYPES.SECONDARY} onClick={onClear}>
        <CloseCircleOutlined /> 清除
      </PrimaryButton>
    </div>
  );
}

FilterActionButtons.propTypes = {
  onFilter: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired
};

export function PopconfirmButton({ disabled, title, description, onConfirm, type, text, btnProps, children }) {
  return (
    <Popconfirm
      disabled={disabled}
      title={title}
      description={description}
      onConfirm={onConfirm}
      okText="確定"
      cancelText="取消"
    >
      {type ? (
        <PrimaryButton type={type} {...btnProps}>
          {text}
          {children}
        </PrimaryButton>
      ) : (
        <Button type="link" {...btnProps}>
          {text}
          {children}
        </Button>
      )}
    </Popconfirm>
  );
}

PopconfirmButton.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.keys(BUTTON_TYPES)),
  text: PropTypes.string
};
