import { ConfigProvider } from "antd";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Loading from "components/Loading";
import PageNotFound from "components/PageNotFound";

import LoadingProvider from "provider/Loading";
import MenuProvider from "provider/Menu";
import LanguageProvider from "provider/Language";

import theme from "utils/theme";
import { routes } from "./routes";
import "./App.css";

function RenderRoutes() {
  return (
    <Routes>
      {routes.map((route) =>
        route.children ? (
          route.children.map((child) => (
            <Route key={child.key} {...child} element={child.element} />
          ))
        ) : (
          <Route key={route.key} {...route} element={route.element} />
        )
      )}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
function App() {
  return (
    <ConfigProvider theme={theme}>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <LoadingProvider>
            <LanguageProvider>
              <MenuProvider>
                <RenderRoutes />
              </MenuProvider>
            </LanguageProvider>
          </LoadingProvider>
        </BrowserRouter>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
