import { Button } from "antd";
import styled from "styled-components";
import { getBoxBorderCssStyle } from "utils/method";

export const PrimaryButtonStyle = styled(Button)`
  background: ${({ background }) => background};
  color: ${({ fontcolor }) => fontcolor};
  border-radius: 8px;
  border-color: ${({ borderColor }) => borderColor};
  &:hover,
  &:focus {
    background: ${({ background }) => background};
    opacity: 0.8;
    color: ${({ fontcolor }) => fontcolor};
  }
  ${(props) => getBoxBorderCssStyle(props)}
`;
