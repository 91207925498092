export default {
  primary: "#BF9D5A",
  secondary: "#c69264",
  tertiary: "#988785",
  fourth: "#E1D4C5",
  white: "white",
  text: { primary: "#4d5d77" },
  blue: {
    dark: "#46587d",
    normal: "#4999c1",
    light: "#53c7ea",
    extraLight: "#e5f8ff",
  },
  gray: { dark: "#c3c3c3", normal: "#f6f7fb", light: "#f9f8f8" },
  purple: { dark: "#a277f8", normal: "#eee5fe" },
  yellow: { dark: "#ffb136", normal: "#f8cc8f", light: "#fff4de" },
  pink: { dark: "#ff5a8d", normal: "#ea63a0", light: "#fff5f8" },
  red: { dark: "#b20051" },
};
