import { Result, Space } from "antd";
import { PrimaryButton } from "components/Buttons";
import { BUTTON_TYPES } from "components/Buttons/define";
import { basename } from "envConfig";
import { useNavigate } from "react-router-dom";
import { unAutherizeDirect } from "utils/method";

function PageNotFound() {
  const navigate = useNavigate();
  const goHome = () => {
    navigate(basename + "/");
  };

  const goLogin = () => {
    unAutherizeDirect();
  };
  return (
    <Result
      status="404"
      title="404"
      subTitle="抱歉，您目前的權限無法觀看此頁面"
      extra={
        <Space>
          <PrimaryButton type={BUTTON_TYPES.LIGHT_PRIMARY} onClick={goLogin}>
            重新登入
          </PrimaryButton>
          <PrimaryButton type={BUTTON_TYPES.PRIMARY} onClick={goHome}>
            回到首頁
          </PrimaryButton>
        </Space>
      }
    />
  );
}
export default PageNotFound;
