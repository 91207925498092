import { isNumber } from "lodash";
import { BOX_BORDER_UNIT, LOCALSTORAGE_TYPES } from "./define";

function getBoxBorderNumber(number) {
  return `${isNumber(number) ? number * BOX_BORDER_UNIT : number}px`;
}
export function getBoxBorderCssStyle({ pl, pb, pt, pr, p, ml, mb, mt, mr, m }) {
  let style = "";
  if (isNumber(pl)) style += `padding-left:${getBoxBorderNumber(pl)};`;
  if (isNumber(pb)) style += `padding-bottom:${getBoxBorderNumber(pb)};`;
  if (isNumber(pt)) style += `padding-top:${getBoxBorderNumber(pt)};`;
  if (isNumber(pr)) style += `padding-right:${getBoxBorderNumber(pr)};`;
  if (isNumber(p)) style += `padding:${getBoxBorderNumber(p)};`;

  if (isNumber(ml)) style += `margin-left:${getBoxBorderNumber(ml)};`;
  if (isNumber(mb)) style += `margin-bottom:${getBoxBorderNumber(mb)};`;
  if (isNumber(mt)) style += `margin-top:${getBoxBorderNumber(mt)};`;
  if (isNumber(mr)) style += `margin-right:${getBoxBorderNumber(mr)};`;
  if (isNumber(m)) style += `margin:${getBoxBorderNumber(m)};`;
  return style;
}

export function getBoxBorderStyle({ pl, pb, pt, pr, p, ml, mb, mt, mr, m }) {
  let style = {};
  if (isNumber(pl)) style.paddingLeft = getBoxBorderNumber(pl);
  if (isNumber(pb)) style.paddingBottom = getBoxBorderNumber(pb);
  if (isNumber(pt)) style.paddingTop = getBoxBorderNumber(pt);
  if (isNumber(pr)) style.paddingRight = getBoxBorderNumber(pr);
  if (isNumber(p)) style.padding = getBoxBorderNumber(p);

  if (isNumber(ml)) style.marginLeft = getBoxBorderNumber(ml);
  if (isNumber(mb)) style.marginBottom = getBoxBorderNumber(mb);
  if (isNumber(mt)) style.marginTop = getBoxBorderNumber(mt);
  if (isNumber(mr)) style.marginRight = getBoxBorderNumber(mr);
  if (isNumber(m)) style.margin = getBoxBorderNumber(m);

  return style;
}

export function checkHasAutherize(userRoles = [], routeRoles = []) {
  if (routeRoles.length) return !!userRoles.find((role) => routeRoles.includes(role));
  else return true;
}

export function unAutherizeDirect() {
  const isLoginPage = window.location.pathname === "/login";
  window.localStorage.removeItem(LOCALSTORAGE_TYPES.TOKEN);
  if (!isLoginPage) window.location.href = "/login";
}

export const numberWithCommas = (x = 0) => {
  let tmp = x.toString();
  return tmp.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const filterNilObj = (values) => {
  return Object.entries(values).reduce((obj, [key, value]) => {
    if (value) obj[key] = value;
    return obj;
  }, {});
};

export function hideScrollbar(classNames) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  return classNames.reduce(
    (string, className) =>
      string +
      `${className}::-webkit-scrollbar {
    display: none;
  }
/* Hide scrollbar for IE, Edge and Firefox */
${className} {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }`,
    ""
  );
}
