import { useState } from "react";

import Context from "./context";
import { Languages } from "./define";

function Provider({ children }) {
  const [language, setLanguage] = useState(Languages.TW);

  const value = { language, setLanguage };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default Provider;
