import PALETTE from "./palette";

export default {
  token: {
    colorBgContainer: PALETTE.white,
    colorPrimary: PALETTE.primary,
    // its for custom component, see example in RoutesSideMenus
    Menu: {
      colorBgContainer: PALETTE.white,
    },
    Content: {
      colorBgContainer: PALETTE.gray.normal,
    },
    Footer: {
      colorBgContainer: PALETTE.white,
    },
    Header: {
      colorBgContainer: PALETTE.white,
    },
    Button: {
      colorBgPrimary: PALETTE.primary,
      colorBgBlue: PALETTE.blue.normal,
      colorBgPink: PALETTE.pink.light,
      colorBgYellow: PALETTE.yellow.light,
      colorBgSecondary: PALETTE.gray.dark,
      colorBgPurple: PALETTE.purple.normal,
    },
    Card: {
      colorBgSecondary: PALETTE.gray.light,
    },
    Palette: PALETTE,
  },
  components: {
    Button: {
      colorPrimary: PALETTE.primary,
    },
  },
};
