import { basename } from "envConfig";
import { checkHasAutherize } from "utils/method";
import mainRoutes from "./main";

export { default as mainRoutes } from "./main";

function addBasename(r) {
  const path = r.path === "/" ? "" : r.path;
  if (r.children)
    return {
      ...r,
      path: basename + path,
      children: r.children.map((c) => addBasename(c)),
    };
  else return { ...r, path: basename + path };
}
export const routes = [...mainRoutes].reduce((arr, r) => {
  arr.push(addBasename(r));
  return arr;
}, []);

export function filterPermissionRoutes(user) {
  return routes.reduce((_routes, route) => {
    if (checkHasAutherize(user.roles, route.roles)) _routes.push(route);
    return _routes;
  }, []);
}

export function filterMenuDisplayRoute(routes) {
  return routes.reduce((_routes, route) => {
    if (route.children)
      return [
        ..._routes,
        { ...route, children: filterMenuDisplayRoute(route.children) },
      ];
    if (route.display) return [..._routes, route];
    else return _routes;
  }, []);
}
