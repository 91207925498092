import { lazy } from "react";

const HomePage = lazy(() => import("pages/HomePage"));

const Routes = [
  {
    key: "home",
    path: "/",
    element: <HomePage />,
    label: { TW: "首頁" },
    display: false,
  },
  {
    key: "geo",
    path: "#geo",
    label: { TW: "地理環境", VN: "Môi trường địa lý" },
    display: true,
  },
  {
    key: "concept",
    path: "#concept",
    label: { TW: "品牌故事", VN: "Câu chuyện thương hiệu" },
    display: true,
  },
  {
    key: "introduce",
    path: "#introduce",
    label: { TW: "產品介紹", VN: "Giới thiệu sản phẩm" },
    display: true,
  },
  {
    key: "service",
    path: "#service",
    label: { TW: "安心服務", VN: "Dịch vụ đảm bảo" },
    display: true,
  },
  {
    key: "contact",
    path: "#contact",
    label: { TW: "聯絡資訊", VN: "Thông tin liên hệ" },
    display: true,
  },
];

export default [...Routes];
