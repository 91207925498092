export const BUTTON_TYPES = {
  PRIMARY: "primary",
  LIGHT_PRIMARY: "lightPrimary",
  SECONDARY: "secondary",
  LIGHT_BLUE: "lightBlue",
  BLUE: "blue",
  DARK_BLUE: "darkBlue",
  PINK: "pink",
  DARK_PINK: "darkPink",
  YELLOW: "yellow",
  DARK_YELLOW: "darkYellow",
  PURPLE: "purple"
};

export const BUTTON_SIZE_TYPES = {
  SMALL: "small",
  MIDDLE: "middle",
  LARGE: "large"
};
